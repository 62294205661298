import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import Layout from '../components/Layout';
import ComingSoon from '../components/ComingSoon';
import * as styles from './donate.module.scss';
import Fade from 'react-reveal/Fade';

const Donate = ({ location }) => {
    const [values, setValues] = useState({
        programme:
            typeof location.state !== 'undefined'
                ? location.state.programme
                : '',
        amount: '300',
        monthly: false,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: 'Malaysia',
        state: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (e.target.type === 'checkbox') {
            setValues({
                ...values,
                [name]: e.target.checked
            });
        } else {
            setValues({
                ...values,
                [name]: value
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <Layout darkBg={true}>
            <div className={styles.paymentContainer}>
                <div className={styles.bgContainer}>
                    <div className={styles.bg}>
                        <StaticImage
                            src="../images/payment-header.jpg"
                            alt="Children Smiling"
                            className={styles.bgImage}
                        />
                        <div className={styles.opacityLayer}>
                            <div className={styles.texts}>
                                <Fade right distance="50px" duration={900}>
                                    <h1>
                                        Help us ensure no one is left behind
                                        <br />
                                        in the digital era.
                                    </h1>
                                </Fade>

                                <Fade
                                    right
                                    distance="50px"
                                    duration={900}
                                    delay={100}
                                >
                                    <div className={styles.quote}>
                                        <p>
                                            “Education is the most powerful
                                            weapon which you can use to change
                                            the world.” - Nelson Mendela
                                        </p>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>

                <ComingSoon />
            </div>
        </Layout>
    );
};

export default Donate;
