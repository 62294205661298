import * as styles from './ComingSoon.module.scss';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const ComingSoon = () => {
    return (
        <div className={styles.comingSoonContainer}>
            <div className={styles.imageContainer}>
                <StaticImage
                    src="../images/coming-soon.png"
                    alt="Coming Soon"
                    className={styles.image}
                    placeholder="blurred"
                    objectFit="contain"
                />
            </div>

            <h2>Our Donation Page is Coming Soon!</h2>

            <Link className={styles.blueBtn} to="/">
                Go Back Home
            </Link>
        </div>
    );
};

export default ComingSoon;
